import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('simple_player', (currentSettings, uiTemplate, shownDuration, overridePlayerRatio, trackingID) => ({
  localSettings: currentSettings,
  uiTemplate: uiTemplate,
  shownDuration: shownDuration,
  overridePlayerRatio: overridePlayerRatio,
  trackingID: trackingID,
  enableTracking: false,
  firstUnmute: true,
  simplePlayerEl: null,
  player: null,
  defaultSettings: {
    autostart: false,
    aspectratio: '16:9'
  },
  enableCustomUI: false,
  enabledUITemplate: {},
  showCustomUI: true,
  uiTimeoutState: null,
  loading: true,
  init() {
    
    if(this.localSettings.videoId === undefined || this.localSettings.videoId === null) {
      console.error('Video ID is not set');
      return;
    }

    this.simplePlayerEl = this.$el;

    this.defaultSettings.playlist = "https://cdn.jwplayer.com/v2/media/" + this.localSettings.videoId;
    
    delete this.localSettings.videoId;

    this.defaultSettings = {...this.defaultSettings, ...this.localSettings}

    if(this.$refs.player_div === undefined) {
      console.error('Player div not set');
      return;
    }

    this.player = jwplayer(this.$refs.player_div).setup(this.defaultSettings);

    this.enableTracking = this.trackingID != false;

    this.player.on("ready", (e) => {
      this.loading = false;

      if(this.enableTracking) {
        this.trackReadyEvent();
      }
    })

    this.player.on("mute", (e) => {

      if(this.enableTracking && this.firstUnmute && !e.mute) {
        this.trackFirstUnmuteEvent();
      }

      if(!e.mute) {
        this.firstUnmute = false;
      }
    })

    if(this.uiTemplate != 'false') {
      
      this.setupUI();
    
    } else {

      if(this.shownDuration) {
        this.player.on('time', (e) => {
          if(e.position > this.shownDuration) {
            this.player.seek(0)
          }
        });
      }
    }

  },
  setupUI() {
    
    const uiTemplates = {
      episode: {
        top: true,
        ui: ['backBtn'],
        dynamic: 2000,
      }
    }

    if(!(this.uiTemplate in uiTemplates)) {
      console.error('UI template not found');
      return;
    }

    this.enableCustomUI = true;
    this.enabledUITemplate = uiTemplates[this.uiTemplate];

    if(this.enabledUITemplate.dynamic !== false) {
      let context = this;
      this.simplePlayerEl.addEventListener('mousemove', function (e) {
        context.showCustomUI = true;

        if(this.uiTimeoutState !== null) {
          clearTimeout(this.uiTimeoutState)
        }
        this.uiTimeoutState = setTimeout(() => {
          context.showCustomUI = false;
        }, context.enabledUITemplate.dynamic);
      })
    }
    
  },
  play(context = this) {
    if(context.player) {
      context.player.play();
    }
  },
  pause(context = this) {
    if (context.player) {
      context.player.pause();
    }
  },
  enableUIComponent(uiName) {
    return this.enabledUITemplate.ui ? this.enabledUITemplate.ui.indexOf(uiName) !== -1 : false;
  },
  trackReadyEvent() {
    plausible('video_player_event', {props: {tracking_id: this.trackingID, event: 'player_ready'}})
  },
  trackFirstUnmuteEvent() {
    plausible('video_player_event', {props: {tracking_id: this.trackingID, event: 'player_first_unmute'}})
  }
}));